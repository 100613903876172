export function LoginDetail(res:any){
    return {type:'LoginDetail',res};
}
export function Vanish(){
    return {type:'Vanish'};
}

export function SetTheme(res: any){
    return {type:'SetTheme',res};
}

export function SetLanguage(res: any){
    return {type:'SetLanguage',res};
}

export function SetDeviceTypeList(res: any){
    return {type:'SetDeviceTypeList',res};
}

export function SetDeviceList(res: any){
    return {type:'SetDeviceList',res};
}

export function SetDeviceSetting(res: any){
    return {type:'SetDeviceSetting',res};
}

export function SetUserDetail(res: any){
    return {type:'SetUserDetail',res};
}

export function SetAllUsers(res: any){
    return {type:'SetAllUsers',res};
}

export function SetSensorList(res: any){
    return {type:'SetSensorList',res};
}

export function SetSelectedUserId(res: any){
    return {type:'SetSelectedUserId',res};
}
export function SetTemplateDetail(res: any){
    return {type:'SetTemplateDetail',res};
}




