import React, { useState, useEffect } from "react";
import ChartDetail from "./chartDetail";
import BackToLogin from "./backToLogin";
import "./chartDetail.scss";

const Dashboard: React.FC = (props: any) => {

  const [loadTheChart, setloadTheChart] = useState(false)
  useEffect(() => {
    (async function anyNameFunction() {
      setloadTheChart(true)
    })();
  }, []);

  return (
    <div id="PaymentSettingsPage">
      {props.auth ? (
        <div>
          <ChartDetail selectedUserId={null}/>
        </div>
      ) : (
          <BackToLogin />
          // <AreaChart />
        )}
    </div>
  );

}

export default Dashboard;
