import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import SensorSetting from "./sensorSetting";
import "./chartDetail.scss";
import {
	getUser,
	getDevice,
	getDeviceSetting,
	updateSensorSetting,
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import {
	SetDeviceTypeList,
	SetDeviceList,
	SetDeviceSetting,
	SetUserDetail,
} from "../../redux/action";
import _ from "lodash";
import moment from "moment";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import LineChart from "./lineChart";
import { useTranslation } from "react-multi-lang";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				// borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-disabled fieldset": {
				borderColor: "#c7d100",
			},
		},
	},
})(TextField);

export default function ChartDetail(props: any) {
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state: any) => state.LoginData);
	const theme = useSelector((state: any) => state.themeData);

	const [sTypeId, setTypeId] = useState("");
	const [sTypeName, setTypeName] = useState("");

	const typeList = useSelector((state: any) => state.deviceTypeList);
	const [sTypeList, setTypeList] = useState(typeList);

	const deviceList = useSelector((state: any) => state.deviceList);
	const [sDeviceList, setDeviceList] = useState(deviceList);

	const deviceSettingList = useSelector((state: any) => state.deviceSetting);
	const [sDeviceSettingList, setDeviceSettingList] =
		useState(deviceSettingList);

	const [sensor, setSensor] = useState([]);

	const [deviceIds, setDeviceIds] = React.useState([]);
	const [uniqueIds, setUniqueIds] = React.useState([]);

	const [deviceDetail, setDeviceDetail] = React.useState([]);

	const [bar, setBar] = React.useState(true);

	const currentDate = moment().toDate();
	const previousDate = moment(currentDate).subtract(40, "days").toDate();

	const [loadGraph, setLoadGraph] = React.useState(true);
	const [startDate, setStartDate] = React.useState(currentDate);
	const [endDate, setEndDate] = React.useState(previousDate);

	useEffect(() => {
		getUserList();
	}, []);

	useEffect(() => {
		const lastDate = moment(startDate).subtract(40, "days").toDate();
		setEndDate(lastDate);
	}, [startDate]);

	useEffect(() => {
		getDeviceListById(sTypeId, deviceIds, uniqueIds);
	}, [startDate, endDate]);

	useEffect(() => {
		getUserList();
	}, [props.selectedUserId]);

	const getUserList = async () => {
		let request = _.clone(getUser);
		let userID = user.attributes.sub;
		if (props.selectedUserId) {
			userID = props.selectedUserId.UserID;
		}

		request.url = request.url + "/?UserID=" + `"${userID}"`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			setTypeId("");
			setTypeName("");
			setTypeList([]);
			dispatch(SetDeviceTypeList([]));
			setDeviceSettingList([]);
			setDeviceIds([]);
			setUniqueIds([]);

			if (result.data.UserID) {
				dispatch(SetUserDetail(result.data));
				if (result.data.Users.Devices.length) {
					const deviceDetail = result.data.Users.Devices[0];
					setDeviceDetail(deviceDetail);
					const deviceTypeList: any = _.keys(deviceDetail);
					setTypeId(deviceTypeList[0]);
					setTypeName(deviceTypeList[0]);
					setTypeList(deviceTypeList);
					dispatch(SetDeviceTypeList(deviceTypeList));
					let deviceIds: any = [];
					let uniqueIds: any = [];
					if (deviceDetail[deviceTypeList[0]]) {
						if (deviceDetail[deviceTypeList[0]].length) {
							deviceDetail[deviceTypeList[0]].map(
								(detail: any, index: any) => {
									deviceIds.push(detail.DeviceId);
									let data = {
										uniqueId: detail.UniqueId,
										deviceName: detail.DeviceName
											? detail.DeviceName
											: detail.UniqueId,
									};
									uniqueIds.push(data);
								}
							);
						}
					}
					deviceIds = _.uniq(deviceIds);
					uniqueIds = _.uniq(uniqueIds);
					setUniqueIds(uniqueIds);
					setDeviceIds(deviceIds);
					if (deviceIds.length) {
						getDeviceSettingById(deviceIds[0]);
					}
					getDeviceListById(deviceTypeList[0], deviceIds, uniqueIds);
				} else {
					setLoadGraph(false);
				}
			} else {
				setLoadGraph(false);
			}
		}
	};

	const getDeviceSettingById = async (deviceId: any) => {
		let request = _.clone(getDeviceSetting);
		let userId: any = user.attributes.sub;
		if (props.selectedUserId) {
			userId = props.selectedUserId.UserID;
		}
		request.url =
			request.url +
			"/?DeviceID=" +
			`"${deviceId}"` +
			"&UserID=" +
			`"${userId}"`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			dispatch(SetDeviceSetting([]));
			setDeviceSettingList([]);
			if (result.data.data.someDeviceSetting.length) {
				const deviceSetting = result.data.data.someDeviceSetting;
				dispatch(SetDeviceSetting(deviceSetting));
				setDeviceSettingList(deviceSetting);
			}
		}
	};

	const getDeviceListById = async (
		deviceTypeId: any,
		deviceIds: any,
		uniqueIds: any
	) => {
		setDeviceList([]);
		setSensor([]);
		setBar(true);
		setLoadGraph(false);
		let device: any = [];
		if (uniqueIds.length) {
			const timeStampStartDate = moment(moment(endDate).format()).format(
				"x"
			);
			const timeStampEndDate = moment(moment(startDate).format()).format(
				"x"
			);
			setLoadGraph(true);
			const promises = uniqueIds.map(async (detail: any, index: any) => {
				let request = _.clone(getDevice);
				// Have to pass query params here
				request.url =
					request.url +
					"?UniqueID=" +
					`"${detail.uniqueId}"` +
					"&" +
					"StartDate=" +
					`"${timeStampStartDate}"` +
					"&" +
					"EndDate=" +
					`"${timeStampEndDate}"`;
				request.header = {
					"Content-Type": "application/json",
					Authorization: user.signInUserSession.idToken.jwtToken,
				};
				const result: any = await getDataFromApi(request);
				if (result.data.message === "The incoming token has expired") {
					history.push("/");
				}else
				if (result.data.data.someDeviceOnUniqueID.length) {
					result.data.data.someDeviceOnUniqueID.map(
						(detail: any, index: any) => {
							device.push(detail);
						}
					);
				}
			});
			const AllEmployeeData: any = await Promise.all(promises);
			setLoadGraph(false);
			if (device.length) {
				dispatch(SetDeviceList(device));
				setDeviceList(device);
				let sensorDetail: any = [];
				const promises = device.map((detail: any, index: any) => {
					uniqueIds.map((uDetail: any, uIndex: any) => {
						if (uDetail.uniqueId === detail.UniqueID) {
							const data = {
								x: new Date(
									moment.unix(detail.Timestamp).format("lll")
								),
								// 'x': new Date(1994, 2, 1),
								y: parseFloat(detail.Payload.Data.Value),
								z: detail.Timestamp,
								[uDetail.deviceName]: parseFloat(
									parseFloat(detail.Payload.Data.Value) === 1
										? parseFloat(
												detail.Payload.Data.Value
										  ) * 10
										: parseFloat(
												detail.Payload.Data.Value
										  ) === 0
										? 5
										: detail.Payload.Data.Value
								),
								uniqueId: detail.UniqueID,
								name: uDetail.deviceName,
								sensorValue: parseFloat(
									parseFloat(detail.Payload.Data.Value) === 1
										? parseFloat(
												detail.Payload.Data.Value
										  ) * 10
										: parseFloat(
												detail.Payload.Data.Value
										  ) === 0
										? 5
										: detail.Payload.Data.Value
								),
							};
							if (detail.Payload.Data.Value > 1) {
								setBar(false);
							}
							sensorDetail.push(data);
						}
					});
				});
				const AllData: any = await Promise.all(promises);
				sensorDetail = _.sortBy(sensorDetail, ["z"]);
				setSensor(sensorDetail);
			}
		}
	};

	const handleChange = (e: any) => {
		setTypeId(e.target.value);
		setTypeName(e.target.value);
		setDeviceIds([]);
		setDeviceSettingList([]);
		dispatch(SetDeviceSetting([]));
		setSensor([]);
		setUniqueIds([]);
		let deviceIds: any = [];
		let uniqueIds: any = [];
		const dDetail: any = deviceDetail;
		if (dDetail[e.target.value].length) {
			dDetail[e.target.value].map((detail: any, index: any) => {
				deviceIds.push(detail.DeviceId);
				let data = {
					uniqueId: detail.UniqueId,
					deviceName: detail.DeviceName
						? detail.DeviceName
						: detail.UniqueId,
				};
				uniqueIds.push(data);
			});
		}
		deviceIds = _.uniq(deviceIds);
		uniqueIds = _.uniq(uniqueIds);
		setDeviceIds(deviceIds);
		setUniqueIds(uniqueIds);
		getDeviceListById(e.target.value, deviceIds, uniqueIds);
		if (deviceIds.length) {
			getDeviceSettingById(deviceIds[0]);
		}
	};

	const updateUserSetting = async (sensorSettingDetail: any) => {
		try {
			let request = _.clone(updateSensorSetting);
			request.url = request.url;
			request.header = {
				"Content-Type": "application/json",
				Authorization: user.signInUserSession.idToken.jwtToken,
			};
			deviceIds.map(async (detail: any, index: any) => {
				const data = {
					// DeviceID: sensorSettingDetail.DeviceID,
					DeviceID: detail,
					UserSettings: sensorSettingDetail.UserSettings,
				};
				request.data = data;
				const result: any = await getDataFromApi(request);
				if (result.data.message === "The incoming token has expired") {
					history.push("/");
				}else

				if (deviceIds.length - 1 === index) {
					store.addNotification({
						message: "Update Successfully !..",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1000,
							onScreen: true,
						},
					});
				}
			});
		} catch (error) {
			console.log("error", error);
		}
	};

	const onChangeDate = (date: any) => {
		setStartDate(date);
	};

	return (
		<div className="dMargin dashboard">
			<Grid container spacing={3}>
				<Grid item xs={12} sm={3} lg={3} xl={3}>
					<CssTextField
						select
						className="sensorDropdown"
						id="outlined-select-currency"
						label={t("Type")}
						variant="outlined"
						value={sTypeList.length ? sTypeId : ""}
						onChange={handleChange}
					>
						{sTypeList.length &&
							sTypeList.map((data: any, index: any) => (
								<MenuItem key={data} value={data}>
									{t(data)}
								</MenuItem>
							))}
						)
					</CssTextField>
				</Grid>
				<Grid item xs={12} sm={3} lg={3} xl={3}>
					<div
						className={
							theme === "light"
								? "date-label-light"
								: "date-label-dark"
						}
					>
						{t("dateLabel")}
					</div>
					<DatePicker
						selected={startDate}
						onChange={(
							date: Date | [Date | null, Date | null] | null
						) => onChangeDate(date)}
						maxDate={currentDate}
						className={`light-input ${
							theme === "dark" && "dark-input"
						}`}
						dateFormat="dd/MM/yyyy"
					/>
					<Grid item xs={12} sm={3} lg={3} xl={3}></Grid>
				</Grid>
				<Grid item xs lg sm xl />
			</Grid>
			<Grid
				item
				xs={12}
				lg={12}
				md={12}
				xl={12}
				style={{ marginTop: "40px" }}
			>
				<Paper className={sensor.length ? "legend" : "noFound"}>
					{!loadGraph ? (
						sensor.length ? (
							<LineChart
								data={sensor}
								deviceIds={uniqueIds}
								bar={bar}
							/>
						) : (
							<span>{t("note")}:+41 76 306 77 66</span>
						)
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<CircularProgress />
						</div>
					)}
				</Paper>
			</Grid>
			{sDeviceSettingList.length ? (
				<Grid
					item
					xs={12}
					lg={12}
					md={12}
					xl={12}
					style={{ marginTop: "40px" }}
				>
					<SensorSetting
						detail={sDeviceSettingList}
						updateSettings={updateUserSetting}
					/>
				</Grid>
			) : (
				""
			)}
		</div>
	);
}
