const live = "https://co38xf20t1.execute-api.eu-central-1.amazonaws.com/dev/";

export const url = live;

export const getUser = {
  url: url + "user",
  method: "GET",
  header: {}
};

export const getDevice = {
  url: url + "deviceData",
  method: "GET",
  header: {}
};

export const getDeviceSetting = {
  url: url + "deviceSetting",
  method: "GET",
  header: {}
};

export const updateSensorSetting = {
  url: url + "UserSetting",
  method: "POST",
  header: {},
  data: {}
}

export const addUser = {
  url: url + "UserData",
  method: "POST",
  header: {},
  data: {}
}

export const updateUser = {
  url: url + "UserDataUpdate",
  method: "POST",
  header: {},
  data: {}
}

export const getTemplates = {
  url: url + "usercaseTemplate",
  method: "GET",
  header: {},
}

export const addSensorSetting = {
  url: url + "settingDetails",
  method: "POST",
  header: {},
  data: {}
}

export const UpdateAddSettingsByDeviceId = {
  url: url + "updatedeviceSettings",
  method: "POST",
  header: {},
  data: {}
}

export const updateDeviceSettingsUserID = {
  url: url + "updateSettingsUserId",
  method: "POST",
  header: {},
  data: {}
}



