import React, { useState, useEffect } from "react";
import Chart, { Series, Legend, ZoomAndPan, ArgumentAxis, ValueAxis, Label, ScrollBar, Tooltip } from 'devextreme-react/chart';
import _ from 'lodash';

export default function LineChart(props) {
    const [bar, setbar] = useState(props.bar)
    const [chartType, setChartType] = useState([])
    const [maxValue, setMaxValue] = useState(0)
    const [minValue, setMinValue] = useState(0)

    const onLegendClick = ({ target: series }) => {
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }
    useEffect(() => {
        const temp = []
        props.deviceIds.map((u) => {
            let data = {
                id: u.deviceName,
                type: 'bar'
            }
            props.data.map((d) => {
                let a = d[u.deviceName]
                if (d[u.deviceName] === 10) {
                    a = d[u.deviceName] / 10
                }
                if (d[u.deviceName] === 5) {
                    a = 0
                }
                if (a > 1) {
                    data = {
                        id: u.deviceName,
                        type: 'spline'
                    }
                }
            })
            temp.push(data)
        })
        setChartType(temp)
        const maxValues = _.maxBy(props.data, 'sensorValue');
        const minValues = _.minBy(props.data, 'sensorValue');
        setMaxValue(maxValues.sensorValue)
        setMinValue(minValues.sensorValue)
    }, []);

    return (
        <Chart
            id="chart"
            dataSource={props.data}
            onLegendClick={onLegendClick}
        // title="Economy - Export Change"
        >
            {
                props.deviceIds.length && (chartType.map((detail,index) =>
                    // <LineSeries name={detail} valueField={detail} argumentField="x" />
                    <Series key={index} type={detail.type} argumentField="x" valueField={detail.id} name={detail.id} />
                ))
            }
            <ValueAxis
                title="Sensor value"
                visualRange={{ startValue: minValue === 5 ? 0 : minValue, endValue: maxValue }}>
            </ValueAxis>
            <ArgumentAxis title="TimeStamp"> </ArgumentAxis>
            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center">
            </Legend>
            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <Tooltip enabled={true} />
        </Chart>
    );
}
