import React from "react";
import { useTranslation } from "react-multi-lang";

function FormErrors(props: any) {
	const t = useTranslation();
	if (
		props.formerrors &&
		(props.formerrors.blankfield || props.formerrors.passwordmatch)
	) {
		return (
			<div className="error container help is-danger">
				<div className="row justify-content-center">
					{t(props.formerrors.passwordmatch)
						? t("The passwords do not match")
						: ""}
				</div>
				<div className="row justify-content-center help is-danger">
					{t(props.formerrors.blankfield)
						? t("All fields are required")
						: ""}
				</div>
			</div>
		);
	} else if (props.apierrors) {
		return (
			<div className="error container help is-danger">
				<div className="row justify-content-center">
					{t(props.apierrors)}
				</div>
			</div>
		);
	} else if (props.formerrors && props.formerrors.cognito) {
		return (
			<div className="error container help is-danger">
				<div className="row justify-content-center">
					{props.formerrors.cognito.message
						? t(props.formerrors.cognito.message)
						: props.formerrors.cognito.message}
				</div>
			</div>
		);
	} else {
		return <div />;
	}
}

export default FormErrors;
