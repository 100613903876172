import { REHYDRATE } from "redux-persist";
var initState = {
  LoginData: null,
  themeData: 'dark',
  languageData: 'de',
  deviceTypeList: [],
  deviceList: [],
  deviceSetting: [],
  userDetail: {},
  allUsers: [],
  sensorList: [],
  userId: null,
  templateDetails: []
};
function farmreducer(state = initState, action:any) {
  switch (action.type) {
    case "LoginDetail":
      return {
        ...state,
        LoginData: action.res
      };
    case "Vanish":
      return {
        ...state,
        LoginData: null,
        //themeData: 'light',
        // languageData: 'de',
        deviceTypeList: [],
        deviceList: [],
        deviceSetting: [],
        userDetail: {},
        allUsers: [],
        sensorList: [],
        userId: null,
        templateDetails: []
      };
      case "SetTheme":
      return {
        ...state,
        themeData: action.res
      };
      case "SetLanguage":
      return {
        ...state,
        languageData: action.res
      };
      case "SetDeviceTypeList":
      return {
        ...state,
        deviceTypeList: action.res
      };
      case "SetDeviceList":
      return {
        ...state,
        deviceList: action.res
      };
      case "SetDeviceSetting":
      return {
        ...state,
        deviceSetting: action.res
      };
      case "SetUserDetail":
      return {
        ...state,
        userDetail: action.res
      };
      case "SetAllUsers":
      return {
        ...state,
        allUsers: action.res
      };
      case "SetSensorList":
      return {
        ...state,
        sensorList: action.res
      };
      case "SetSelectedUserId":
      return {
        ...state,
        userId: action.res
      };
      case "SetTemplateDetail":
      return {
        ...state,
        templateDetails: action.res
      };
    case REHYDRATE:
      return {
        ...state,
        LoginData:
          action.payload && action.payload.LoginData
            ? action.payload.LoginData
            : null,
        languageData:
          action.payload && action.payload.languageData
            ? action.payload.languageData
            : 'de',
        themeData:
            action.payload && action.payload.themeData
              ? action.payload.themeData
              : 'dark',
        deviceTypeList:
          action.payload && action.payload.deviceTypeList
            ? action.payload.deviceTypeList
            : [],
        deviceList:
          action.payload && action.payload.deviceList
            ? action.payload.deviceList
            : [],
        deviceSetting:
          action.payload && action.payload.deviceSetting
            ? action.payload.deviceSetting
            : [],
        userDetail:
          action.payload && action.payload.userId
            ? action.payload.userId
            : {},
        allUsers:
          action.payload && action.payload.allUsers
            ? action.payload.allUsers
            : {},
        sensorList:
          action.payload && action.payload.sensorList
          ? action.payload.sensorList
          : {},
        userId:
          action.payload && action.payload.userId
            ? action.payload.userId
            : {},
        templateDetails:
          action.payload && action.payload.templateDetails
            ? action.payload.templateDetails
            : {},
      };
    default:
      return {
        ...state
      };
  }
}
export const reducer = farmreducer;
