import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import FormErrors from "../validation/FormErrors";
import Validate from "../validation/FormValidation";
import { Auth } from "aws-amplify";
import "./changePassword.scss";
import { useTranslation } from 'react-multi-lang'
import { withStyles } from '@material-ui/core/styles';
import { store } from 'react-notifications-component';
import { useSelector, useDispatch } from 'react-redux';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#c7d100',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#c7d100',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: '#c7d100',
      },
      '&:hover fieldset': {
        borderColor: '#c7d100',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c7d100',
      },
    },
  },
})(TextField);

export default function UpdatePassword(props: { auth: { setAuthStatus: (arg0: boolean) => void; setUser: (arg0: any) => void; }; history: string[]; location: any}) {
  const history = useHistory();
  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmpassword: "",
    errors: {
      cognito: {},
      blankfield: false
    }
  })
//   const user = useSelector((state: any) => state.LoginData)
//   console.log(user)

//   useEffect(() => {
//     Auth.signOut();
//     dispatch(Vanish());
//     if(props.location.search){
//       const str = props.location.search.replaceAll("?", "");  
//       dispatch(SetLanguage(str))
//     }
//   }, [])


  const clearErrorState = () => {
    setState({
      ...state, errors: {
        cognito: {},
        blankfield: false
      }
    })
  };

  const onInputChange = async (event: { target: { name: any; value: any; }; }) => {

    const { name, value } = event.target
    await setState({ ...state, [name]: value })

    //document.getElementById(event.target.id).classList.remove("is-danger");
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    // Form validation
    clearErrorState();
    const error = Validate(event, state);
    if (error) {
      setState({ ...state, ...error });
    }
    if (state.oldPassword === "") {
      let err = {code: "InvalidParameterException", name: "InvalidParameterException", message: t("Please enter a old password.")};
      setState({ ...state, errors: { cognito: err, blankfield: false } });
    }
    else if (state.newPassword === "") {
        let err = {code: "InvalidParameterException", name: "InvalidParameterException", message: t("Please enter a new password.")};
        setState({ ...state, errors: { cognito: err, blankfield: false } });
    }
    else if (state.confirmpassword === "") {
        let err = {code: "InvalidParameterException", name: "InvalidParameterException", message: t("Please enter a new confirm password.")};
        setState({ ...state, errors: { cognito: err, blankfield: false } });
    }

    else if (state.confirmpassword !== state.newPassword) {
        let err = {code: "InvalidParameterException", name: "InvalidParameterException", message: t("New password & new confirm password does not match.")};
        setState({ ...state, errors: { cognito: err, blankfield: false } });
      }
    else {
      // AWS Cognito integration here
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userDetail = await Auth.changePassword(user,state.oldPassword,state.newPassword);
        store.addNotification({
            message: "Password Update Successfully..!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1000,
              onScreen: true
            }
          });
          history.push('/');
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setState({ ...state, errors: { cognito: err, blankfield: false } });
      }
    }
  };
  const t = useTranslation()

  return (
    <section className="section">
      <div className="container cpContainer">
        <div className="avtarDiv">
          <Avatar></Avatar>
        </div>
        <div className="cpHeader">{t('Change Password')}</div>
        <form
          onSubmit={handleSubmit}
          className="cpForm"
          noValidate
          autoComplete="off"
        >
          <div className="cpFormMargin">
          <div>
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                defaultValue={state.oldPassword}
                onChange={onInputChange}
                type="password"
                id="oldPassword"
                label={t('Old Password')}
                name="oldPassword"
              // autoComplete="username"
              />
            </div>
            <div>
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                defaultValue={state.newPassword}
                onChange={onInputChange}
                type="password"
                id="newPassword"
                label={t('Password')}
                name="newPassword"
              // autoComplete="username"
              />
            </div>
            <div>
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                defaultValue={state.confirmpassword}
                onChange={onInputChange}
                type="password"
                id="confirmpassword"
                label={t('Confirm Password')}
                name="confirmpassword"
              // autoComplete="username"
              />
            </div>
            <div className="errorMsg">
              <FormErrors formerrors={state.errors} />
            </div>
            <div className="cpBtn">
              <Button
                type="button"
                fullWidth
                variant="outlined"
                onClick={handleSubmit}
              >
                {t("Change Password")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}