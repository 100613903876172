export const DropdownDetails = [
    {
        "DropdownName" : "MagnetSetting",
        "DropdownValue":   [
            "CloseMagnet",
            "OpenMagnet"
                ]
    },
    {
        "DropdownName" : "MilktempWithoutCooling",
        "DropdownValue":   [
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
                ]
    },
    {
        "DropdownName" : "TotalHeight",
        "DropdownValue":  [
        "400",
        "390",
        "380",
        "370",
        "360",
        "350",
        "340",
        "330",
        "320",
        "310",
        "300",
        "290",
        "280",
        "270",
        "260",
        "250",
        "240",
        "230",
        "220",
        "210",
        "200",
        "190",
        "180",
        "170",
        "160",
        "150",
        "140",
        "130",
        "120",
        "110",
        "100"
            ],
    },
    {
        "DropdownName" : "TotalWeightKilo",
        "DropdownValue":  [
        "4000",
        "3900",
        "3800",
        "3700",
        "3600",
        "3500",
        "3400",
        "3300",
        "3200",
        "3100",
        "3000",
        "2900",
        "2800",
        "2700",
        "2600",
        "2500",
        "2400",
        "2300",
        "2200",
        "2100",
        "2000",
        "1900",
        "1800",
        "1700",
        "1600",
        "1500",
        "1400",
        "1300",
        "1200",
        "1100",
        "1000"
    ],
},
{
    "DropdownName" : "SensorGap",
    "DropdownValue":  
    [
        "0",
        "10",
        "20",
        "30",
        "40",
        "50"
        ]
    }   
]
  
 