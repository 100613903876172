import React, { useState, useEffect } from "react";
import {
	Typography,
	Grid,
	Link,
	TextField,
	Button,
	Avatar,
	Stepper,
	Step,
	StepLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FormErrors from "../validation/FormErrors";
import Validate from "../validation/FormValidation";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-multi-lang";
import "./signUp.scss";
import _, { forEach, reject } from "lodash";
import { addUser } from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { VcardAlarm } from "./ALARM_eyeoe";

const getSteps = () => {
	return [
		"User Info",
		"Contact Info",
		"Address Info",
		"Login Info",
		"Verify Account",
	];
};

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				//   borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
		},
	},
})(TextField);

function vCardDownload() {
	var element = document.createElement("a");
	element.setAttribute(
		"href",
		"data:text/x-vcard;charset=base64," + encodeURIComponent(VcardAlarm)
	);
	element.setAttribute("download", "eyeoeAlarm.vcf");

	element.style.display = "none";
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

export default function Registration(props: {
	auth: {
		setAuthStatus: (arg0: boolean) => void;
		setUser: (arg0: any) => void;
	};
	history: string[];
}) {
	const history = useHistory();
	const [activeStep, setActiveStep] = React.useState(0);
	const steps = getSteps();
	const [nextDisabled, setNextDisabled] = useState(true);
	const [registerDisabled, setRegisterDisabled] = useState(true);
	const [verifyDisabled, setVerifyDisabled] = useState(true);
	const user = useSelector((state: any) => state.LoginData);
	const setLanguage = useSelector((state: any) => state.languageData);

	const [state, setState] = useState({
		username: "",
		email: "",
		password: "",
		confirmpassword: "",
		surname: "",
		firstname: "",
		contactno1: "",
		contactno2: "",
		contactno3: "",
		street: "",
		postcode: "",
		place: "",
		code: "",
		errors: {
			cognito: {},
			blankfield: false,
			passwordmatch: false,
		},
	});

	const handleNext = async () => {
		await setActiveStep((prevActiveStep) => prevActiveStep + 1);
		ButtonValidate(activeStep + 1);
	};

	const handleBack = async () => {
		await setActiveStep((prevActiveStep) => prevActiveStep - 1);
		clearErrorState();
		ButtonValidate(activeStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const clearErrorState = () => {
		setState({
			...state,
			errors: {
				cognito: {},
				blankfield: false,
				passwordmatch: false,
			},
		});
	};

	const onInputChange = async (event: {
		target: { name: any; value: any };
	}) => {
		const { name, value } = event.target;
		let err = {};
		let nextDisabled = false;
		if (
			event.target.name === "surname" &&
			(value.match(/\s/g) || value === "")
		) {
			nextDisabled = true;
			err = {
				code: "InvalidParameterException",
				name: "InvalidParameterException",
				message: t(
					"Please enter just one Surname or link the names with a hyphen"
				),
			};
		} else if (
			event.target.name === "firstname" &&
			(value.match(/\s/g) || value === "")
		) {
			nextDisabled = true;
			err = {
				code: "InvalidParameterException",
				name: "InvalidParameterException",
				message: t(
					"Please enter just one Firstname or link the names with a hyphen"
				),
			};
		} else if (
			(event.target.name === "contactno1" ||
				event.target.name === "contactno2" ||
				event.target.name === "contactno3") &&
			(value.match(/[^+0-9]/g) ||
				!value.match(/^\+/) ||
				value.length > 13)
		) {
			nextDisabled = true;
			err = {
				code: "InvalidParameterException",
				name: "InvalidParameterException",
				message: t(
					"Please enter the number in this fromat +41763337766 without space"
				),
			};
		} else if (state.surname === "" || state.firstname === "") {
			nextDisabled = true;
		} else {
			nextDisabled = false;
		}

		setNextDisabled(nextDisabled);
		await setState({
			...state,
			[name]: value,
			errors: { cognito: err, blankfield: false, passwordmatch: false },
		});
	};

	useEffect(() => {
		ButtonValidate(activeStep);
	}, [state]);

	const ButtonValidate = (activeIndex: any) => {
		const {
			username,
			email,
			password,
			confirmpassword,
			surname,
			firstname,
			contactno1,
			street,
			postcode,
			place,
			code,
		} = state;

		// clearErrorState();

		let nextDisable = true;
		let registerDisabled = true;
		let verifyDisabled = true;
		if (activeIndex === 0) {
			// if (surname !== '' && firstname !== '') {
			nextDisable = nextDisabled;
			// }
		}
		if (activeIndex === 1) {
			if (contactno1 !== "") {
				nextDisable = false;
			}
		}
		if (activeIndex === 2) {
			if (street !== "" && postcode !== "" && place !== "") {
				nextDisable = false;
			}
		}
		if (activeIndex === 3) {
			if (username !== "" && password !== "" && confirmpassword != "") {
				registerDisabled = false;
			}
		}
		if (activeIndex === 4) {
			if (code.length === 6) {
				verifyDisabled = false;
			}
		}

		setNextDisabled(nextDisable);
		setRegisterDisabled(registerDisabled);
		setVerifyDisabled(verifyDisabled);
	};

	const handleKeyPress = async (event: any) => {
		if (event.key === "Enter") {
			if (
				event.target.name === "surname" ||
				event.target.name === "firstname" ||
				event.target.name === "email" ||
				event.target.name === "contactno1" ||
				event.target.name === "contactno2" ||
				event.target.name === "contactno3" ||
				event.target.name === "street" ||
				event.target.name === "postcode" ||
				event.target.name === "place"
			) {
				nextDisabled || handleNext();
			}
			if (
				event.target.name === "username" ||
				event.target.name === "password" ||
				event.target.name === "confirmpassword"
			) {
				registerDisabled || handleSubmit(event);
			}
			if (event.target.name === "code") {
				verifyDisabled || verifyAccount();
			}
		}
	};

	const redirectLogin = () => {
		history.push("/");
	};

	const preventSubmit = async (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};
	const handleSubmit = async (event: { preventDefault: () => void }) => {
		event.preventDefault();
		// Form validation
		clearErrorState();

		const error = Validate(event, state);
		if (error) {
			setState({ ...state, ...error });
		}

		// AWS Cognito integration here

		const {
			username,
			email,
			password,
			confirmpassword,
			surname,
			firstname,
			contactno1,
			contactno2,
			contactno3,
			street,
			postcode,
			place,
		} = state;
		if (password === confirmpassword) {
			try {
				const signUpResponse = await Auth.signUp({
					username,
					password,
					attributes: {
						email: email,
						"custom:custom:surname": surname,
						"custom:custom:firstname": firstname,
						phone_number: contactno1,
						"custom:custom:contactno1": contactno1,
						"custom:custom:contactno2": contactno2,
						"custom:custom:contactno3": contactno3,
						"custom:custom:street": street,
						"custom:custom:postcode": postcode,
						"custom:custom:place": place,
						"custom:custom:language": setLanguage,
						"custom:role": "user",
					},
				}).then(async (result: any) => {
					console.log("result..", result);

					const contacts = [];
					if (contactno1) {
						contacts.push(contactno1);
					}
					if (contactno2) {
						contacts.push(contactno2);
					}
					if (contactno3) {
						contacts.push(contactno3);
					}
					const r = result;
					try {
						let request = _.clone(addUser);
						request.url = request.url;
						request.header = {
							"Content-Type": "application/json",
							// "Authorization": user.signInUserSession.idToken.jwtToken
						};
						const data = {
							UserID: r.userSub,
							Users: {
								Address: {
									Place: place,
									Postcode: postcode,
									Street: street,
								},
								Devices: [
									{
										//   "DEMO": [
										//     {
										//       "DeviceId": "12C934D",
										//       "Permissions": {
										//         "SensorDataRead": true,
										//         "SettingsRead": true,
										//         "SettingsWrite": true
										//       }
										//     }
										//   ],
										//   "Motion0501": [
										//     {
										//       "DeviceId": "104488C",
										//       "Permissions": {
										//         "SensorDataRead": true,
										//         "SettingsRead": true,
										//         "SettingsWrite": true
										//       }
										//     }
										//   ]
									},
								],
								Email: email,
								IsActive: true,
								Language: setLanguage,
								Name: surname + " " + firstname,
								Phone: contacts,
								Poweruseraccess: {},
								Type: "",
								Username: username,
							},
						};
						request.data = data;
						const result: any = await getDataFromApi(request);
						if (
							result.data.message ===
							"The incoming token has expired"
						) {
							history.push("/");
						}
						setState({
							...state,
							errors: {
								cognito: {},
								blankfield: false,
								passwordmatch: false,
							},
						});
					} catch (error) {}
				});
				setActiveStep(4);
			} catch (error) {
				console.log("errr..", error);

				let err = null;
				!error.message ? (err = { message: error }) : (err = error);

				setState({
					...state,
					errors: {
						cognito: err,
						blankfield: false,
						passwordmatch: false,
					},
				});
			}
		} else {
			let err = {
				code: "InvalidParameterException",
				name: "InvalidParameterException",
				message: t("The passwords do not match"),
			};
			setState({
				...state,
				errors: {
					cognito: err,
					blankfield: false,
					passwordmatch: false,
				},
			});
		}
	};
	const verifyAccount = async () => {
		const { username, code } = state;
		try {
			await Auth.confirmSignUp(username, code, {
				forceAliasCreation: true,
			})
				.then((data) => {
					history.push("/signup_success");
				})
				.catch((error) => {
					let err = null;
					!error.message ? (err = { message: error }) : (err = error);

					setState({
						...state,
						errors: {
							cognito: err,
							blankfield: false,
							passwordmatch: false,
						},
					});
				});
		} catch (error) {
			let err = null;
			!error.message ? (err = { message: error }) : (err = error);
			setState({
				...state,
				errors: {
					cognito: err,
					blankfield: false,
					passwordmatch: false,
				},
			});
		}
	};
	const t = useTranslation();
	return (
		<section className="sectionogRegister">
			<div className="container signUpContainer">
				<div className="avtarDivRegister">
					<Avatar></Avatar>
				</div>
				<div className="lodinHeader">{t("Sign Up")}</div>

				<form className="loginForm" onSubmit={preventSubmit}>
					<div className="signUpFormMargin">
						<Grid direction="row" container spacing={1}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Stepper
									activeStep={activeStep}
									alternativeLabel
								>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel>{t(label)}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Grid>
							{activeStep === 0 && (
								<>
									<Grid item xs></Grid>
									<Grid item>
										<Typography
											align="center"
											variant="body2"
											gutterBottom
										>
											{t(
												"eyeoe alerts via a phone number"
											)}
										</Typography>
										<Button
											type="button"
											fullWidth
											variant="outlined"
											onClick={vCardDownload}
										>
											{t("Save alarm number")}
										</Button>
									</Grid>
									<Grid item xs></Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="surname"
											name="surname"
											label={t("Surname")}
											defaultValue={state.surname}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoFocus
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="firstname"
											name="firstname"
											label={t("Firstname")}
											defaultValue={state.firstname}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="email"
											name="email"
											label={t("Email")}
											type="email"
											defaultValue={state.email}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoComplete="Email"
										/>
									</Grid>
								</>
							)}
							{activeStep === 1 && (
								<>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											placeholder="+41763337766"
											id="contactno1"
											name="contactno1"
											label={t("Mobile No")}
											defaultValue={state.contactno1}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoFocus
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="contactno2"
											name="contactno2"
											label={t("Office No")}
											defaultValue={state.contactno2}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="contactno3"
											name="contactno3"
											label={t("Phone No")}
											defaultValue={state.contactno3}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
										/>
									</Grid>
								</>
							)}
							{activeStep === 2 && (
								<>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="street"
											name="street"
											label={t("Street")}
											defaultValue={state.street}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoFocus
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="postcode"
											name="postcode"
											label={t("Postcode")}
											defaultValue={state.postcode}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="place"
											name="place"
											label={t("Place")}
											defaultValue={state.place}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
										/>
									</Grid>
								</>
							)}
							{activeStep === 3 && (
								<>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="username"
											name="username"
											label={t("Username")}
											defaultValue={state.username}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoComplete="username"
											autoFocus
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="password"
											name="password"
											label={t("Password")}
											type="password"
											defaultValue={state.password}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoComplete="password"
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="confirmpassword"
											name="confirmpassword"
											label={t("Confirm Password")}
											type="password"
											defaultValue={state.confirmpassword}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoComplete="Confirm password"
										/>
									</Grid>
								</>
							)}
							{activeStep === 4 && (
								<>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<CssTextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="code"
											name="code"
											label={t("Verification Code")}
											type="code"
											defaultValue={state.code}
											onChange={onInputChange}
											onKeyPress={handleKeyPress}
											autoComplete="code"
											autoFocus
										/>
									</Grid>
								</>
							)}
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								className="errorMsg"
							>
								<FormErrors formerrors={state.errors} />
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								{activeStep !== 0 &&
								activeStep !== steps.length - 1 ? (
									<Button
										type="button"
										fullWidth
										variant="outlined"
										disabled={activeStep === 0}
										onClick={handleBack}
									>
										{t("Back")}
									</Button>
								) : (
									""
								)}
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								{activeStep === steps.length - 2 ? (
									<Button
										type="button"
										fullWidth
										variant="outlined"
										onClick={handleSubmit}
										disabled={registerDisabled}
									>
										{t("Register")}
									</Button>
								) : activeStep === steps.length - 1 ? (
									<Button
										type="button"
										fullWidth
										variant="outlined"
										onClick={verifyAccount}
										disabled={verifyDisabled}
									>
										{t("Verify Account")}
									</Button>
								) : (
									<Button
										type="button"
										fullWidth
										variant="outlined"
										onClick={handleNext}
										disabled={nextDisabled}
									>
										{t("Next")}
									</Button>
								)}
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								className="loginRouteLink pb-50"
								container
							>
								<Grid item xs></Grid>
								<Grid item>
									<Link
										onClick={() => {
											redirectLogin();
										}}
										variant="body2"
									>
										{t("Already have an account? Sign in")}
									</Link>
								</Grid>
								<Grid item xs></Grid>
							</Grid>
							{/* <div> 
                                <Grid item xs={12} sm={12} md={12} lg={12} className="loginRouteLink pb-50" container>
                                <Grid item xs>
                                </Grid>
                                {
                                    activeStep === 4 ? 
                                        <Grid item>
                                        <Link 
                                            onClick={() => {
                                                resendCode();
                                            }}
                                            variant="body2"
                                        >
                                            {t('Resend code')}
                                        </Link>
                                    </Grid>
                                    : ''
                                }
                                <Grid item xs>
                                </Grid>
                            </Grid>                            
                            </div> */}
						</Grid>
					</div>
				</form>
			</div>
		</section>
	);
}
