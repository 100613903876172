import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import config from "./config.json";

import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});
ReactDOM.render(
  
  <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>    
            <App/>    
        </PersistGate>
    </Provider>, 
    document.getElementById('root')
);
serviceWorker.unregister();
