import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Grid,
	MenuItem,
	CircularProgress,
	TextField,
	Button,
	Paper,
	Dialog,
	DialogActions,
	DialogTitle,
	useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-multi-lang";
import {
	getTemplates,
	getUser,
	updateUser,
	addSensorSetting,
	getDeviceSetting,
	UpdateAddSettingsByDeviceId,
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { SetTemplateDetail } from "../../redux/action";
import _ from "lodash";
import { withStyles, useTheme } from "@material-ui/core/styles";
import CropFreeIcon from "@material-ui/icons/CropFree";
import QrReader from "react-qr-reader";
import { store } from "react-notifications-component";
import "./addSensor.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import {DropdownDetails} from './dropDownData'

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				// borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-disabled fieldset": {
				borderColor: "#c7d100",
			},
		},
	},
})(TextField);

const AddSensor: React.FC = (props: any) => {
	const t = useTranslation();
	const history = useHistory();
	const userId = useSelector((state: any) => state.userId);
	let allUsers = useSelector((state: any) => state.allUsers);
	const dispatch = useDispatch();
	const [templateList, setTemplates] = React.useState([]);
	const [sTypeId, SetSTypeId] = React.useState("");
	const [template, SetTemplate] = React.useState([]);
	const [userTemplates, SetUserTemplate] = React.useState([]);
	const [deviceTemplate, SetDeviceTemplate] = React.useState<any>([]);
	const [apiCall, setApiCall] = React.useState(false);
	const [result, setResult] = React.useState("No result");
	const [scanIndex, setScanIndex] = React.useState(null);
	const [tempApiCall, setTempApiCall] = React.useState(false);
	const [allSettingList, setAllSettingList] = React.useState([]);
	const user = useSelector((state: any) => state.LoginData);
	const sUserDetail = useSelector((state: any) => state.sensorList);
	const [selectedUserDetail, setSelectedUserDetail] =
		React.useState(sUserDetail);
	const [existDeviceId, setExistDeviceId] = React.useState(null);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [openDialog, setOpenDialog] = React.useState(false);

	const getAllTemplates = async () => {
		let request = _.clone(getTemplates);
		request.url = request.url;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);

		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			dispatch(SetTemplateDetail([]));
			setTemplates([]);
			SetSTypeId("");
			SetTemplate([]);
			SetUserTemplate([]);
			SetDeviceTemplate([]);
			if (result.data.data.allUseCaseTemplates) {
				if (result.data.data.allUseCaseTemplates.length) {
					dispatch(
						SetTemplateDetail(result.data.data.allUseCaseTemplates)
					);
					setTemplates(result.data.data.allUseCaseTemplates);
				}
			}
			setTempApiCall(true);
			getAllDeviceSettings();
		}
	};

	const getAllDeviceSettings = async () => {
		let request = _.clone(getDeviceSetting);
		request.url = request.url;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			setAllSettingList([]);
			if (result.data.data.allDeviceSettings.length) {
				const deviceSetting = result.data.data.allDeviceSettings;
				setAllSettingList(deviceSetting);
			}
		}
	};

	useEffect(() => {
		getAllTemplates();
	}, []);

	const handleChange = async (e: any) => {
		SetSTypeId(e.target.value);
		SetTemplate([]);
		const promises = templateList.map((detail: any, index: any) => {
			if (detail.UseCaseId === e.target.value) {
				if (selectedUserDetail.Users.Devices.length) {
					if (selectedUserDetail.Users.Devices[0][e.target.value]) {
						let senosrDetail = _.find(
							allSettingList,
							function (o: any) {
								return (
									o.DeviceID ===
									selectedUserDetail.Users.Devices[0][
										e.target.value
									][0].DeviceId
								);
							}
						);
						if (
							senosrDetail &&
							detail.template.DeviceSettingsTemplate.length
						) {
							detail.template.DeviceSettingsTemplate.map(
								(dDetail: any, dIndex: any) => {
									detail.template.DeviceSettingsTemplate[
										dIndex
									].UserSettings = senosrDetail.UserSettings;
								}
							);
						}
					}
				}
				SetTemplate(detail.template);
				SetUserTemplate(detail.template.userTemplate);
				SetDeviceTemplate(detail.template.DeviceSettingsTemplate);
			}
		});
		const AllEmployeeData = await Promise.all(promises);
		getSensorListByUserId();
	};

	const getSensorListByUserId = async () => {
		let request = _.clone(getUser);
		request.url = request.url + "/?UserID=" + `"${userId}"`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else if (result.data.UserID) {
			setSelectedUserDetail(result.data);
		}
	};

	const handleError = (err: any) => {
		console.error(err);
	};
	const handleScan = (data: any) => {
		let value = data;
		setResult(value);
		if (value) {
			handleDeviceIdChange(value, scanIndex, "DeviceId");
		}
	};

	const handleScanIndex = (index: any) => {
		if (
			window.location.protocol === "http:" &&
			window.location.hostname != "localhost"
		) {
			store.addNotification({
				message: "Camera can only be accessed over https or localhost",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
				},
			});
		} else {
			setScanIndex(index);
		}
	};

	const backToAdd = () => {
		setScanIndex(null);
	};

	const handleDeviceIdChange = (value: any, index: any, field: any) => {
		let templateDetail: any = { ...template };
		let deviceTemplateDetail: any = [...deviceTemplate];
		let userTemplateDetail: any = [...userTemplates];

		if (field === "DeviceName") {
			templateDetail.DeviceSettingsTemplate[index].DeviceName = value;
			templateDetail.userTemplate[index].DeviceName = value;

			userTemplateDetail[index][field] = value;
		}

		if (deviceTemplateDetail[index].AdminSettings.length > 0){
			deviceTemplateDetail[index].AdminSettings.map((detail: any, indexAdmin: any) => {
			  templateDetail.DeviceSettingsTemplate.map((templateDetail2: any, templateDetailIndex: any) => { //mapping through all indexes to change value all Sensors (milk usecase has 3 Sensors)
			  try {
				if (field === detail.settingName){
				  templateDetail.DeviceSettingsTemplate[templateDetailIndex].AdminSettings[indexAdmin].settingValue = value
				  deviceTemplateDetail[templateDetailIndex].AdminSettings[indexAdmin].settingValue = value
				  SetDeviceTemplate(deviceTemplateDetail)
				}
			  } catch (error) {
				console.log('error', error)
			  }
			})
		  })
		}

		if (field === "DeviceId") {
			userTemplateDetail[index][field] = value;
			let deviceIDValue = value ? value.replace(/^0/g, "") : value;

			deviceTemplateDetail = deviceTemplateDetail.map(
				(detail: any, dIndex: any) => {
					if (
						(detail.StaticAdminSettings.CompressorDeviceID ||
							detail.StaticAdminSettings.CompressorDeviceID === "") &&
						index === 2
					) {
						detail.StaticAdminSettings["CompressorDeviceID"] =
							"U" + deviceIDValue;
					}
					if (
						(detail.StaticAdminSettings.MotorDeviceID ||
							detail.StaticAdminSettings.MotorDeviceID === "") &&
						index === 1
					) {
						detail.StaticAdminSettings["MotorDeviceID"] =
							"U" + deviceIDValue;
					}
					if (
						(detail.StaticAdminSettings.TempDeviceID ||
							detail.StaticAdminSettings.TempDeviceID === "") &&
						index === 0
					) {
						detail.StaticAdminSettings["TempDeviceID"] =
							"U" + deviceIDValue;
					}
				}
			);
			templateDetail.DeviceSettingsTemplate[index].DeviceID =
				deviceIDValue;
			templateDetail.DeviceSettingsTemplate[index].UniqueID =
				"U" + deviceIDValue;
			templateDetail.DeviceSettingsTemplate[index].UserID = userId;
			templateDetail.DeviceSettingsTemplate[index]["update"] = false;
			templateDetail.userTemplate[index].DeviceId = value;
			templateDetail.userTemplate[index].UniqueId = "U" + deviceIDValue;
		}
		SetUserTemplate(userTemplateDetail);
		SetTemplate(templateDetail);
		setScanIndex(null);
	};

	const addDeviceDetail = async () => {
		setApiCall(true);

		let templateData = {
			UseCaseId: sTypeId,
			template: template,
		};

		const uDetail = selectedUserDetail;
		let templateDetail: any = template;

		templateDetail.userTemplate.map((detail: any, index: any) => {
			templateDetail.userTemplate[index]["DeviceId"] = templateDetail
				.userTemplate[index]["DeviceId"]
				? templateDetail.userTemplate[index]["DeviceId"].replace(
						/^0/g,
						""
				  )
				: templateDetail.userTemplate[index]["DeviceId"];
		});

		if (uDetail.Users.Devices[0]) {
			if (uDetail.Users.Devices[0][sTypeId]) {
				uDetail.Users.Devices[0][sTypeId] = uDetail.Users.Devices[0][
					sTypeId
				].concat(templateDetail.userTemplate);
				uDetail.Users.Devices[0][sTypeId] = _.uniq(
					uDetail.Users.Devices[0][sTypeId]
				);
			} else {
				uDetail.Users.Devices[0][sTypeId] = templateDetail.userTemplate;
			}
		}
		let UserDetail = uDetail;
		let DeviceSetting = templateDetail.DeviceSettingsTemplate;

		let existDevice = false;
		let existDeviceNullUserId = false;
		let DeviceId = null;
		setExistDeviceId(null);
		DeviceSetting.map(async (detail: any, index: any) => {
			let deviceDetail = _.find(allSettingList, function (o: any) {
				return o.DeviceID === detail.DeviceID;
			});
			if (deviceDetail && !existDevice) {
				if (deviceDetail.UserID !== "") {
					existDevice = true;
					existDeviceNullUserId = false;
				} else {
					if (!detail.update) {
						existDevice = false;
						existDeviceNullUserId = true;
						setExistDeviceId(detail.DeviceID);
					}
				}
				DeviceId = detail.DeviceID;
			}
		});

		if (!existDevice && !existDeviceNullUserId) {
			try {
				let request = _.clone(updateUser);
				request.url = request.url;
				request.header = {
					"Content-Type": "application/json",
					Authorization: user.signInUserSession.idToken.jwtToken,
				};
				request.data = UserDetail;
				const result: any = await getDataFromApi(request);
				if (result.data.message === "The incoming token has expired") {
					history.push("/");
				} else if (result.flag) {
					DeviceSetting.map(async (detail: any, index: any) => {
						try {
							let request = _.clone(addSensorSetting);
							request.url = request.url;
							request.header = {
								"Content-Type": "application/json",
								Authorization:
									user.signInUserSession.idToken.jwtToken,
							};

							const data: any = {
								StaticAdminSettings: {
									Mode: detail.StaticAdminSettings.Mode,
								},
								DeviceID: detail.DeviceID,
								DeviceName: detail.DeviceName,
								UniqueID: detail.UniqueID,
								UserID: detail.UserID,
								UserSettings: detail.UserSettings,
								AdminSettings: [],
							};
							if (detail.StaticAdminSettings.CompressorDeviceID) {
								data.StaticAdminSettings["CompressorDeviceID"] =
									detail.StaticAdminSettings.CompressorDeviceID;
							}
							if (detail.StaticAdminSettings.MotorDeviceID) {
								data.StaticAdminSettings["MotorDeviceID"] =
									detail.StaticAdminSettings.MotorDeviceID;
							}
							if (detail.StaticAdminSettings.TempDeviceID) {
								data.StaticAdminSettings["TempDeviceID"] =
									detail.StaticAdminSettings.TempDeviceID;
							}
							detail.AdminSettings.map(async (AdminDetails: any, indexAdmin: any) => {
								try {
								  console.log(data)
								  if (AdminDetails.settingName.substring(AdminDetails.settingName.lastIndexOf('_') + 1) !== "List"){
									data.AdminSettings.push({})
									data.AdminSettings[indexAdmin].settingName = AdminDetails.settingName
									data.AdminSettings[indexAdmin].settingValue = AdminDetails.settingValue
								  }
				
								} catch (error) {
								  console.log('error', error)
								}
							  })

							request.data = data;
							const settingResult: any = await getDataFromApi(
								request
							);
							if (
								settingResult.data.message ===
								"The incoming token has expired"
							) {
								history.push("/");
							}

							if (
								settingResult.flag &&
								DeviceSetting.length - 1 === index
							) {
								store.addNotification({
									message: "Add Successfully !..",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animated", "fadeIn"],
									animationOut: ["animated", "fadeOut"],
									dismiss: {
										duration: 1000,
										onScreen: true,
									},
								});
								setApiCall(false);
								history.push("/sensors");
							}
						} catch (error) {
							console.log("error", error);
						}
					});
				}
			} catch (error) {
				console.log("error", error);
			}
		} else {
			if (existDevice) {
				store.addNotification({
					message: "Device-ID =" + DeviceId + "already exist",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1000,
						onScreen: true,
					},
				});
			}
			if (existDeviceNullUserId) {
				setOpenDialog(true);
			}
			setApiCall(false);
		}
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const overwriteSensorDetail = () => {
		let templateDetail: any = template;
		let index = _.findIndex(
			templateDetail.DeviceSettingsTemplate,
			function (o: any) {
				return o.DeviceID === existDeviceId;
			}
		);
		templateDetail.DeviceSettingsTemplate[index].update = true;
		setExistDeviceId(null);
		setOpenDialog(false);
		addDeviceDetail();
	};
	return (
		<div className="addSensorDiv">
			<Paper className="paperM">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span>
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Name
								: ""}
							{", "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Street
								: ""}{" "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Place
								: ""}{" "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Postcode
								: ""}
						</span>
					</Grid>
				</Grid>
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={3} md={3} lg={3}>
					{scanIndex === null ? (
						tempApiCall ? (
							<CssTextField
								select
								className="sensorDropdown"
								id="outlined-select-currency"
								label="Type"
								variant="outlined"
								value={sTypeId}
								onChange={(e) => handleChange(e)}
							>
								{templateList.length &&
									templateList.map(
										(data: any, index: any) => (
											<MenuItem
												key={data.UseCaseId}
												value={data.UseCaseId}
											>
												{t(data.UseCaseId)}
											</MenuItem>
										)
									)}
								)
							</CssTextField>
						) : (
							<Paper className="addSensorPaper">
								<CircularProgress />
							</Paper>
						)
					) : (
						<Button variant="outlined" onClick={backToAdd}>
							{t("Back")}
						</Button>
					)}
				</Grid>
			</Grid>

			{scanIndex !== null ? (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<QrReader
							delay={300}
							facingMode={"environment"}
							showViewFinder={true}
							onError={handleError}
							onScan={handleScan}
						/>
					</Grid>
				</Grid>
			) : userTemplates.length ? (
				<>
					{userTemplates.map((data: any, index: any) => (
						<Grid container>
							<Grid item xs={12} sm={6} md={3} lg={3}>
								<Paper className="addSensorPaper">
									<div>
										<CssTextField
											fullWidth
											variant="outlined"
											margin="normal"
											required
											value={data.DeviceId}
											id="DeviceId"
											label={t("DeviceID")}
											// name="deviceid"
											onChange={(e) =>
												handleDeviceIdChange(
													e.target.value,
													index,
													"DeviceId"
												)
											}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<CropFreeIcon
															className="scanIcon"
															onClick={(e) =>
																handleScanIndex(
																	index
																)
															}
														/>
													</InputAdornment>
												),
											}}
										/>
									</div>
									<div>
										<CssTextField
											fullWidth
											variant="outlined"
											margin="normal"
											required
											value={data.DeviceName}
											id="DeviceName"
											label={t("Name")}
											// name="deviceid"
											onChange={(e) =>
												handleDeviceIdChange(
													e.target.value,
													index,
													"DeviceName"
												)
											}
										/>
									</div>
									{deviceTemplate[index].AdminSettings.map(( AdminData :any , indexAdmin: any) =>
										index === 0 ? // just display Admin Settings of the first sensor --> adding same value to all Sensors
										DropdownDetails.map(( DropDownData :any , indexDropDown: any) =>
										AdminData.settingName ===  DropDownData.DropdownName ?
										DropDownData.DropdownValue.length ?
										<div>
												<CssTextField
												select
												key={AdminData.indexAdmin} 
												fullWidth
												variant="outlined"    
												margin="normal"
												required
												value={deviceTemplate[index].AdminSettings[indexAdmin].settingValue}
												id= {AdminData.settingName}
												label={AdminData.settingName}
												style={{ textAlign: 'left' }}
												onChange={(e) => handleDeviceIdChange(e.target.value, index, AdminData.settingName)}
												>
												{
													DropDownData.DropdownValue.length && (
													DropDownData.DropdownValue.map((dropDownData2: any, dropDownIndex2: any) =>
														<MenuItem key={dropDownData2.dropDownIndex2} value={dropDownData2}>{t(dropDownData2)}</MenuItem>
													)
													)}
												</CssTextField>
											</div> 
										: ''
										: '')
										: '' // from index === 0 ?
										)
									}
								</Paper>
							</Grid>
							<Grid item xs={12} sm={6} md={9} lg={9}></Grid>
						</Grid>
					))}
					<Grid item xs={12}>
						<Button
							variant="outlined"
							disabled={apiCall ? true : false}
							onClick={addDeviceDetail}
						>
							{apiCall ? <CircularProgress /> : t("Update")}
						</Button>
					</Grid>
					<Dialog
						fullScreen={fullScreen}
						open={openDialog}
						onClose={handleClose}
						aria-labelledby="responsive-dialog-title"
						className="dialog"
					>
						<DialogTitle id="responsive-dialog-title">
							{"Wan't to overwrite existing sensor settings? (DeviceID = " +
								existDeviceId +
								")"}
						</DialogTitle>
						<DialogActions>
							<span className="deleteBtn">
								<Button
									variant="outlined"
									onClick={overwriteSensorDetail}
									color="primary"
								>
									Overwrite
								</Button>
							</span>
							<span className="cancelBtn">
								<Button
									autoFocus
									variant="outlined"
									onClick={handleClose}
									color="primary"
								>
									Cancel
								</Button>
							</span>
						</DialogActions>
					</Dialog>
				</>
			) : (
				""
			)}
		</div>
	);
};
export default AddSensor;
