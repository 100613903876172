export const DropdownDetails = [
    {
        "DropdownName" : "NumberOfNotifications",
        "unitOfMeasurement": "min",
        "DropdownValue":   [
            '1','2','3'
        ],
    },
    {
        "DropdownName" : "NotificationTime",
        "unitOfMeasurement": "",
        "DropdownValue":   [
            '15','20','25','30','35','40','45','50',
            '55','60'
        ],
    },
    {
        "DropdownName" : "TemperaturNormal",
        "unitOfMeasurement": "°C",
        "DropdownValue":   [
            '0','1','2','3','4','5','6','7','8','9','10',
            '11','12','13','14','15'
        ]
    },
    {
        "DropdownName" : "TemperaturCleaning",
        "unitOfMeasurement": "°C",
        "DropdownValue":   [
            '','40','41','42','43','44','45','46','47','48','49','50',
            '51','52','53','54','55','56','57','58','59','60',
            '61','62','63','64','65','66','67','68','69','70',
            '71','72','73','74','75','76','77','78','79','80',
            '81','82','83','84','85','86','87','88','89','90',
            '91','92','93','94','95','96','97','98','99','100',
        ],
    },
    {
        "DropdownName" : "MilkingMinutes",
        "unitOfMeasurement": "min",
        "DropdownValue":   [
            '50','60','70','80','90','100','110',
            '120','130','140','150','160','170',
            '180','190','200','210','220','230',
            '240',
        ],
    },
    {
        "DropdownName" : "notificationThresholdTemp",
        "unitOfMeasurement": "°C",
        "DropdownValue":   [
            '-30','-29','-28','-27','-26','-25','-24','-23','-22','-21','-20',
            '-19','-18','-17','-16','-15','-14','-13','-12','-11','-10',
            '-9','-8','-7','-6','-5','-4','-3','-2','-1',
            '0','1','2','3','4','5','6','7','8','9','10',
            '11','12','13','14','15','16','17','18','19','20',
            '21','22','23','24','25','26','27','28','29','30',
            '31','32','33','34','35','36','37','38','39','40',
            '41','43','43','44','45','46','47','48','49','50',
            '51','52','53','54','55','56','57','58','59','60',
            '61','62','63','64','65','66','67','68','69','70',
            '71','72','73','74','75','76','77','78','79','80',
            '81','82','83','84','85','86','87','88','89','90',
            '91','92','93','94','95','96','97','98','99','100',
        ],
    },
    {
        "DropdownName" : "temperatureHysteresis",
        "unitOfMeasurement": "min",
        "DropdownValue":   [
            '0','5','10','15','20','25','30','35','40','45','50',
            '55','60','65','70','75','80','85','90','95','100',
        ],
    },
    {
        "DropdownName" : "notificationThresholdTempSummer",
        "unitOfMeasurement": "°C",
        "DropdownValue":   [
            '-30','-29','-28','-27','-26','-25','-24','-23','-22','-21','-20',
            '-19','-18','-17','-16','-15','-14','-13','-12','-11','-10',
            '-9','-8','-7','-6','-5','-4','-3','-2','-1',
            '0','1','2','3','4','5','6','7','8','9','10',
            '11','12','13','14','15','16','17','18','19','20',
            '21','22','23','24','25','26','27','28','29','30',
            '31','32','33','34','35','36','37','38','39','40',
            '41','43','43','44','45','46','47','48','49','50',
            '51','52','53','54','55','56','57','58','59','60',
            '61','62','63','64','65','66','67','68','69','70',
            '71','72','73','74','75','76','77','78','79','80',
            '81','82','83','84','85','86','87','88','89','90',
            '91','92','93','94','95','96','97','98','99','100',
        ],
    },
    {
        "DropdownName" : "StateNotificationTime",
        "unitOfMeasurement": "min",
        "DropdownValue":   [
            '15','30','45','60','75','90',
            '105','120','135','150','165',
            '180','195','210','225','240'
        ],
    },
    {
        "DropdownName" : "CronjobTime",
        "unitOfMeasurement": "h",
        "DropdownValue":   [
            '','00:00','00:15','00:30','00:45',
            '01:00','01:15','01:30','01:45',
            '02:00','02:15','02:30','02:45',
            '03:00','03:15','03:30','03:45',
            '04:00','04:15','04:30','04:45',
            '05:00','05:15','05:30','05:45',
            '06:00','06:15','06:30','06:45',
            '07:00','07:15','07:30','07:45',
            '08:00','08:15','08:30','08:45',
            '09:00','09:15','09:30','09:45',
            '10:00','10:15','10:30','10:45',
            '11:00','11:15','11:30','11:45',
            '12:00','12:15','12:30','12:45',
            '13:00','13:15','13:30','13:45',
            '14:00','14:15','14:30','14:45',
            '15:00','15:15','15:30','15:45',
            '16:00','16:15','16:30','16:45',
            '17:00','17:15','17:30','17:45',
            '18:00','18:15','18:30','18:45',
            '19:00','19:15','19:30','19:45',
            '20:00','20:15','20:30','20:45',
            '21:00','21:15','21:30','21:45',
            '22:00','22:15','22:30','22:45',
            '23:00','23:15','23:30','23:45'
        ]
    },
    {
        "DropdownName" : "notificationThreshold",
        "unitOfMeasurement": "min",
        "DropdownValue":   [
            '60',
            '80','85','90','95','100','105','110','115','120','125','130',
            '135','140','145','150','155','160','165','170','175','180'
        ],
    },
    {
        "DropdownName" : "ControlTime",
        "unitOfMeasurement": "h",
        "DropdownValue":   [
            '','00:00','00:15','00:30','00:45',
            '01:00','01:15','01:30','01:45',
            '02:00','02:15','02:30','02:45',
            '03:00','03:15','03:30','03:45',
            '04:00','04:15','04:30','04:45',
            '05:00','05:15','05:30','05:45',
            '06:00','06:15','06:30','06:45',
            '07:00','07:15','07:30','07:45',
            '08:00','08:15','08:30','08:45',
            '09:00','09:15','09:30','09:45',
            '10:00','10:15','10:30','10:45',
            '11:00','11:15','11:30','11:45',
            '12:00','12:15','12:30','12:45',
            '13:00','13:15','13:30','13:45',
            '14:00','14:15','14:30','14:45',
            '15:00','15:15','15:30','15:45',
            '16:00','16:15','16:30','16:45',
            '17:00','17:15','17:30','17:45',
            '18:00','18:15','18:30','18:45',
            '19:00','19:15','19:30','19:45',
            '20:00','20:15','20:30','20:45',
            '21:00','21:15','21:30','21:45',
            '22:00','22:15','22:30','22:45',
            '23:00','23:15','23:30','23:45'
        ]
    },
    {
        "DropdownName" : "siloMaxPercent",
        "unitOfMeasurement": "%",
        "DropdownValue":   [
            '81','82','83','84','85','86','87','88','89','90',
            '91','92','93','94','95','96','97','98','99','100',
        ],
    },
    {
        "DropdownName" : "SiloMinRemarkPercent",
        "unitOfMeasurement": "%",
        "DropdownValue":   [
            '11','12','13','14','15','16','17','18','19','20',
            '21','22','23','24','25','26','27','28','29','30',
        ],
    },
    {
        "DropdownName" : "SiloMinAlarmPercent",
        "unitOfMeasurement": "%",
        "DropdownValue":   [
            '2','3','4','5','6','7','8','9','10',
        ],
    },
]