import React from "react";
import Button from "@material-ui/core/Button";
import "../signin/signIn.scss";
import { useTranslation } from 'react-multi-lang'

const BackToLogin: React.FC = () => {
  const t = useTranslation()
  return (
    <div id="backtoLoginPage">
      <div className="loginBackHeader">{t('Please back to login')}</div>
      <div className="btn">
        <Button
          variant="outlined"
          href="/"
          size="large"
        >
          {t('Login')}
          </Button>
      </div>
    </div>
  );
}

export default BackToLogin;
