import React from 'react';
import { makeStyles, withStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import {
  CircularProgress, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Button, Dialog, DialogActions,
  DialogTitle, useMediaQuery
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-multi-lang'
import './collapseTable.scss'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props: any) {
  const { row } = props;
  const classes = useRowStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sensorId, setSensorId] = React.useState(null);
  const [cIndex, setCIndex] = React.useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const t = useTranslation()

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#CBCBCB',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#E7E7E7',
        },
      },
    }),
  )(TableRow);

  const removeSensorDetail = () => {
    props.updateSensor(sensorId, cIndex);
    setOpenDialog(false);
  }

  const handleClickOpen = (sensorId: any, cIndex: any) => {
    setSensorId(sensorId)
    setCIndex(cIndex)
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <>
        <TableRow className={classes.root}>
          <TableCell colSpan={4} style={{backgroundColor:'#999797'}}>
            {t(row[0])}
          </TableCell>
        </TableRow>
        {row[1].map((historyRow: any, cindex: any) => (
          <StyledTableRow key={cindex}>
            <TableCell component="th" scope="row">
              {historyRow.DeviceName}
            </TableCell>
            <TableCell>{historyRow.DeviceId}</TableCell>
            <TableCell>{historyRow.UniqueId}</TableCell>
            <TableCell>
              <DeleteIcon onClick={(e) => handleClickOpen(row[0], cindex)} />
            </TableCell>
          </StyledTableRow>
        ))}
      </>
      <>
        <Dialog
          fullScreen={fullScreen}
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className="dialog"
        >
          <DialogTitle id="responsive-dialog-title">{"Are you sure you want to delete this sensor?"}</DialogTitle>
          <DialogActions>
            <span className="deleteBtn">
              <Button variant="outlined" onClick={removeSensorDetail} color="primary">
                Delete
              </Button>
            </span>
            <span className="cancelBtn">
              <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
                Cancel
          </Button>
            </span>
          </DialogActions>
        </Dialog>
      </>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props: any) {
  let { tableHeader, tableBody, apiCall, updateSensor } = props;
  const t = useTranslation()
  if (tableBody.length) {
    tableBody = Object.entries(tableBody[0])
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {tableHeader.map((detail: any, index: any) =>
              <TableCell>{t(detail.label)}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {apiCall === true ?
            tableBody.length ?
              tableBody.map((row: any, index: any) => (
                <Row key={index} row={row} updateSensor={updateSensor} />
              ))
              :
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  No Records Found
              </TableCell>
              </TableRow>
            :
            <TableRow>
              <TableCell align="center" colSpan={4}>
                <CircularProgress />
              </TableCell>
            </TableRow>

          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
