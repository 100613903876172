import {store} from '../redux/store'
//import { fetch } from "whatwg-fetch";

export const getDataFromApi = (request:any) => {
 store.dispatch({type:"Loader",flag:true})
  var promise = new Promise(function(resolve, reject) {
    fetch(request.url, {
      method: request.method,
      headers: request.header,
      body: JSON.stringify(request.data),
    })
      .then((response:any) => response.text())
      .then((responseText:any) => {
        store.dispatch({type:"Loader",flag:false})
        responseText = JSON.parse(responseText);
        if (responseText) {
          resolve({ data: responseText, flag: true });
        } else {
          resolve({ data: [], flag: false });
        }
      })
      .catch((err:any) => {
        store.dispatch({type:"Loader",flag:false})
        reject({ data: err, flag: false });
      });
  });
  return promise;
};


