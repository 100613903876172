import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import FormErrors from "../validation/FormErrors";
import Validate from "../validation/FormValidation";
import { Auth } from "aws-amplify";
import "./resetPassword.scss";
import { useTranslation } from 'react-multi-lang'
import { withStyles } from '@material-ui/core/styles';
import { store } from 'react-notifications-component';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#c7d100',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#c7d100',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: '#c7d100',
      },
      '&:hover fieldset': {
        borderColor: '#c7d100',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c7d100',
      },
    },
  },
})(TextField);



export default function ResetPassword(props: { auth: { setAuthStatus: (arg0: boolean) => void; setUser: (arg0: any) => void; }; history: string[]; location: any}) {
  const history = useHistory();
  const [state, setState] = useState({
    code:"",
    username: "",
    password: "",
    errors: {
      cognito: {},
      blankfield: false
    }
  })

//   useEffect(() => {
//     Auth.signOut();
//     dispatch(Vanish());
//     if(props.location.search){
//       const str = props.location.search.replaceAll("?", "");  
//       dispatch(SetLanguage(str))
//     }
//   }, [])


  const clearErrorState = () => {
    setState({
      ...state, errors: {
        cognito: {},
        blankfield: false
      }
    })
  };

  const onInputChange = async (event: { target: { name: any; value: any; }; }) => {

    const { name, value } = event.target
    await setState({ ...state, [name]: value })

    //document.getElementById(event.target.id).classList.remove("is-danger");
  };

  const redirectLogin = () => {
    history.push("/");
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    // Form validation
    clearErrorState();
    const error = Validate(event, state);
    if (error) {
      setState({ ...state, ...error });
    }
    if (state.code === "") {
        let err = {code: "InvalidParameterException", name: "InvalidParameterException", message: t("Please enter a code.")};
        setState({ ...state, errors: { cognito: err, blankfield: false } });
    }
    else if (state.username === "") {
      let err = {code: "InvalidParameterException", name: "InvalidParameterException", message: t("Please enter a username")};
      setState({ ...state, errors: { cognito: err, blankfield: false } });
    }
    else if (state.password === "") {
        let err = {code: "InvalidParameterException", name: "InvalidParameterException", message: t("Please enter a new password.")};
        setState({ ...state, errors: { cognito: err, blankfield: false } });
    }
    else {
      // AWS Cognito integration here
      try {
        const user = await Auth.forgotPasswordSubmit(state.username,state.code,state.password);
        store.addNotification({
            message: "Password Reset Successfully..!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1000,
              onScreen: true
            }
          });
          setTimeout(function(){ history.push("/"); }, 1000);
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setState({ ...state, errors: { cognito: err, blankfield: false } });
      }
    }
  };
  const t = useTranslation()

  return (
    <section className="section">
      <div className="container rpContainer">
        <div className="avtarDiv">
          <Avatar></Avatar>
        </div>
        <div className="rpHeader">{t('Reset Password')}</div>

        <form
          onSubmit={handleSubmit}
          className="rpForm"
          noValidate
          autoComplete="off"
        >
          <div className="rpFormMargin">
            <div>
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                defaultValue={state.code}
                onChange={onInputChange}
                type="password"
                id="code"
                label={t('Code')}
                name="code"
              />
            </div>
            <div>
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                defaultValue={state.username}
                onChange={onInputChange}
                id="username"
                label={t('Username')}
                name="username"
              // autoComplete="username"
              />
            </div>
            <div>
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                defaultValue={state.password}
                onChange={onInputChange}
                type="password"
                id="password"
                label={t('Password')}
                name="password"
              // autoComplete="username"
              />
            </div>
            <div className="errorMsg">
              <FormErrors formerrors={state.errors} />
            </div>
            <div className="rpBtn">
              <Button
                type="button"
                fullWidth
                variant="outlined"
                onClick={handleSubmit}
              >
                {t("Reset Password")}
              </Button>
            </div>
            <Grid className="rpRouteLink" container>
            <Grid item xs>
            </Grid>
            <Grid item>
              <Link
                // href="javascript:void(0)"
                onClick={() => {
                  redirectLogin();
                }}
                variant="body2"
              >
                {t("Sign In")}
              </Link>
            </Grid>
          </Grid>
          </div>
        </form>
      </div>
    </section>
  );
}
