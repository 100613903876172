import React from "react";
import "./signUp.scss";
import BackToLogin from "../dashboard/backToLogin";
import { Typography, InputLabel, Grid, TextField, Paper, Button } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang'

interface Types {
    auth: any;
}

const SignUpSuccess: React.FC = () => {
    const t = useTranslation()
    return (
        <div className="signUpSuccess">
            <Typography variant="h4" className="signUpSuccess">{t('Registration Successfully')}!..</Typography>
            <Typography variant="h5">{t('Click here for Login')}</Typography>
            <Button
                variant="outlined"
                href="/"
                size="large"
            >
                {t('Login')}
                </Button>
        </div>
    );

}

export default SignUpSuccess;