import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { Menu } from "@material-ui/core/";
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from "@material-ui/core";
import { Person as PersonIcon } from "@material-ui/icons";
import "./newAppBar.scss";
import { useTranslation } from 'react-multi-lang'
import Brightness4RoundedIcon from '@material-ui/icons/Brightness4Rounded';
import Brightness7RoundedIcon from '@material-ui/icons/Brightness7Rounded';
import Tooltip from '@material-ui/core/Tooltip';
import logo from "../../../src/Logo.jpg";


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const language = [
  { value: "en", name: "English" },
  { value: "de", name: "German" },
  { value: "fr", name: "French" },
  { value: "it", name: "Italian" }
];

export default function Navbar(props: any) {
  const history = useHistory();
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [loginAnchorEl, setLoginAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLoginAnchorEl = Boolean(loginAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setLoginAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClick = (event: any) => {
    setLoginAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLoginAnchorEl(null);
  };

  const redirectSetting = () => {
    // setLoginAnchorEl(null);
    history.push("/setting");
    handleMobileMenuClose();
  };

  const handleLogOut = async (event: any) => {
    event.preventDefault();
    handleMobileMenuClose();
    history.push("/");
  };

  const redirectDashboard = async () => {
    if(props.auth.attributes['custom:role'] === 'admin'){
      history.push("/users");
    }else{
      history.push("/dashboard");
    }
    handleMobileMenuClose();
  }

  const redirecteyoePage = async () => {
    window.open("https://www.eyeoe.ch", "_self")
    handleMobileMenuClose();
  }

  const redirectRegister = () => {
    history.push("/signup");
    handleMobileMenuClose();
  };

  const redirectUsers = () => {
    history.push("/users");
    handleMobileMenuClose();
  };

  const redirectDevices = () => {
    history.push("/devices");
    handleMobileMenuClose();
  };
  
  const redirectChangePassword = () => {
    history.push("/changePassword");
    handleMobileMenuClose();
  };


  useEffect(() => {
    if(props.auth === null){    
      // if(window.location.pathname === '/adminSignUp'){
      //   history.push('adminSignUp')
      // } else{
      //   history.push('/')
      // }
      history.push('/')
    }else{
      if(props.auth.attributes && props.auth.attributes['custom:role'] === 'admin'){
        if(window.location.pathname === '/dashboard'){
          history.push('/')
        }
      }
      if(props.auth.attributes && props.auth.attributes['custom:role'] === 'user'){
        if(window.location.pathname === '/users' || window.location.pathname === '/sensors' || window.location.pathname === '/addSensors' || window.location.pathname === '/devices'){
          history.push('/')
        }
      }
    }

  }, []);

  const t = useTranslation();

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {props.theme === 'light' ?
          <Tooltip title="Ligth Theme">
            <Brightness4RoundedIcon onClick={props.onClick} />
          </Tooltip>
          :
          <Tooltip title="Dark Theme">
            <Brightness7RoundedIcon onClick={props.onClick} />
          </Tooltip>
        }
      </MenuItem>
      <hr />
      <>
        {!props.auth && (
          <>
            <MenuItem onClick={redirectRegister}>{t("Register")}</MenuItem>
            <MenuItem onClick={handleLogOut}>{t("Log in")}</MenuItem>
          </>
        )}
      </>
      <>
        {props.auth && (
          <>
            <MenuItem onClick={redirectDashboard}>
              {props.auth && props.auth.username}
            </MenuItem>
            {props.auth.attributes && props.auth.attributes['custom:role'] === 'admin' && (
              <>
                <MenuItem onClick={redirectUsers}>{t("Users")}</MenuItem>           
                <MenuItem onClick={redirectDevices}>{t("Devices")}</MenuItem>            
              </>
            )}
            <MenuItem><span onClick={redirectChangePassword}>{t("Change Password")}</span></MenuItem>
            <MenuItem onClick={redirectSetting}>{t("Settings")}</MenuItem>
            <hr />
            <MenuItem onClick={handleLogOut}>{t("Logout")}</MenuItem>
          </>
        )}
      </>
    </Menu>
  );

  const renderDesktopMenu = (
    <Menu
      anchorEl={loginAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isLoginAnchorEl}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <span onClick={redirectDashboard}>{props.auth && props.auth.username}</span>
      </MenuItem>
      {props.auth && props.auth.attributes && props.auth.attributes['custom:role'] === 'admin' && (
          <>
            <MenuItem onClick={redirectUsers}>{t("Users")}</MenuItem>
            <MenuItem onClick={redirectDevices}>{t("Devices")}</MenuItem>
          </>
      )}
      <MenuItem><span onClick={redirectChangePassword}>{t("Change Password")}</span></MenuItem>
      <MenuItem><span onClick={redirectSetting}>{t("Settings")}</span></MenuItem>
      <hr />
      <MenuItem><span onClick={handleLogOut}>{t("Logout")}</span></MenuItem>
    </Menu>
  );
  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          {!props.auth ? (
            <div onClick={redirecteyoePage} className="pointer">
              <img className="logo" src={logo} />
            </div>
          ) : (
              <div onClick={redirectDashboard} className="pointer">
                <img className="logo" src={logo} />
              </div>
            )}
          <div className={classes.grow} />
          {/* <TranslateRoundedIcon className="translaterIcon" />
          <FormControl className="languageInput">
            <Tooltip title="Change Language">
              <Select
              value={props.SLanguage}
              onChange={props.setLanguage}
              displayEmpty
              className="languageDropdown"
              inputProps={{ 'aria-label': 'Without label' }}
              >
                {language.map((lan) =>
                  <MenuItem value={lan.value}>{lan.name}</MenuItem>
                )}
              </Select>
            </Tooltip>
          </FormControl> */}
          <div className={classes.sectionDesktop}>
            <MenuItem>
              {props.theme === 'light' ?
                <Tooltip title="Ligth Theme">
                  <Brightness4RoundedIcon onClick={props.onClick} />
                </Tooltip>
                :
                <Tooltip title="Dark Theme">
                  <Brightness7RoundedIcon onClick={props.onClick} />
                </Tooltip>
              }
            </MenuItem>
            <>
              {!props.auth && (
                <>
                  <MenuItem onClick={redirectRegister}>
                    {t("Register")}
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>{t("Log in")}</MenuItem>
                </>
              )}
            </>
            <>
              {props.auth && (
                <MenuItem>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleClick}
                  >
                    <PersonIcon />
                  </Button>
                </MenuItem>
              )}
            </>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderDesktopMenu}
    </div>
  );
}
